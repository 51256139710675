<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 IBP 
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_IBP_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Orantech offers a full line of Interface Cables for all popular Disposable Transducers in the industry including the following brands: B.Braun, Becton Dickenson, Edwards, Medex/Abbott, Utah, and PVB. The Adapter Cables and Convert Cables are compatible with all popular brands of patient monitors including: Drager/Siemens, Fukuda Danshi, GE, Nihon Kohden, Mindray/Datascope, Philips, and Spacelabs. Single, double and triple channel compatible Disposable Transducers are also available with private labeling.
        .item-wrap(v-for="(sp, i) in IBPData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    components: {
        AboutProductItem,
        MissionPromiseStatement,
        Footer
    },
    data: () => ({
        IBPData: [
            {
                title: "Adapter and Convert Cables",
                brands: ["Philips", "GE Healthcare", "Mindray/Datascope", "Welch Allyn", "Nihon Kohden", "Draeger/Siemens", "Spacelabs"],
                features: [
                    "Performance and functionality equivalent to OEMs",
                    "Designed to be used with major IBP Transducers like Argon, B.Braun, B.D., Edward, Medex/Abbott, Utah and PVB",
                    "Convert to the existing popular IBP adaptor cables",
                    "Options: Single or Multi- Channel",
                    "CE certificate and FDA 510(K) clearance",
                    "12 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-adaptor-and-convert-cables.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-cables-selection-.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-GE-Marquette-compatible-cable.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBP_DGB_BD40.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-Nihon-Kohden-compatible-cable.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBP_SL_MXT40.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBP_MRA_MIR40.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBP_FDC_UT40.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBP_AM_MXL40.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-Philips-Edwards-cable.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBPA_DG_2DGB03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBPA_MQ_2MQ03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBPA_SL_AM03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBPC_UT_BB03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-IBPC_EW_MXT03.jpg"
                ],
                downloadUrls: [
                    {
                        url: "https://orantech.com/wp-content/uploads/2022/03/DOC-001-03-Declaration-of-Conformity-IBP-Interface-Cables.pdf",
                        message: "Declaration of Conformity IBP Interface Cables"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_IBP_Adapter_Cables.pdf",
                        message: "IBP Adapter Cables User Manual"
                    }
                ]
            },
            {
                title: "Disposable Transducers and Accessories",
                brands: ["Argon", "B.Braun", "B.D.", "Edwards", "Medex/Abbott", "Utah", "PVB"],
                features: [
                    "The alternatives of branded disposable transducers",
                    "Options of Single, double and triple channel",
                    "Optional transducer mounting : 3 or 4 channels",
                    "Cost-effective",
                    "CE certificate and FDA 510(K) clearance"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-alternative-disposable-transducers.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-Orantech-BD-compatible-disposable-transducer.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBPT_MXT.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBPT_BD.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBPT_EW.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBPT_PVB.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-2UT.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC-IBPT-3H.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBPT_4H.jpg"
                ],
            },
            {
                title: "Infusion Bags",
                brands: ["General Purposes", ],
                features: [
                    "Reusable and disposable pressure infusion bags available",
                    "Sizes options of 500ml, 1000ml and 3000ml",
                    "Gauges options of Piston, Aneroid and Metal",
                    "Materials for Disposable : White 70D Nylon with PU Laminate",
                    "Materials for Reusable: Blue 210D Nylon with PU Laminate",
                    "Featured Clear-Cuff Pressure Infusion Bags made of thick, durable polyurethane. Easy viewing of the fluid bag and fluid level.",
                    "FDA registered and with CE  Mark"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBP_RPI500GT.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/IBP-TNC_IBP_RPI1000DT.jpg"
                ]
            },
        ]
    })
  }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>