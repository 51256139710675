<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 EEG
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_EEG_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p We provide the following BIS related cables and sensors: Monitor Interface Cables, BIS Adaptor Cables and BIS Sensors for brands of EEG equipment by the brands: Aspect Medical and Mindray.
        .item-wrap(v-for="(sp, i) in eegData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            eegData: [
                {
                    title: "BIS Monitor Interface Cables",
                    brands: ["Coviden", "Aspect Medical", "GE Healthcare", "Mindray"],
                    features: [
                        "Engineered to functionally equivalent to OEMs",
                        "Wide selection of monitor interface cables",
                        "Securely connecting the Specific monitor to BIS Modules",
                        "Affordable prices",
                        "CE certificated and FDA clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EEG-Orantech-BIS-MIC-cable.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/BISA_MR01_30.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/02/BIS_Host_Cables.pdf",
                            message: "BIS Host Cables Brochure"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "BISA-AP01-30",
                            description: "Aspect Medical 3.0m,used with BIS VISTA monitoring system",
                            brands: "Aspect Medical",
                            references: "185-0145-AMS 185-1016-AMS",
                            package: "1 pc/bag"
                        },
                        {
                            number: "BISA-MR01-30",
                            description: "Mindray 3.0m,used withBeneView T5/T6/T8/T9 with 6800-30-50486 BIS Module",
                            brands: "Mindray",
                            references: "186-0201-MR 040-000676-00",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "BIS Patient Interface Cables",
                    brands: ["Metronic/Coviden", ],
                    features: [
                        "Engineered to functionally equivalent to OEMs",
                        "Used with BIS LoC 2 and 4 channel modules",
                        "Securely connecting with module and sensors",
                        "Big savings as alternatives for after market",
                        "CE certificated and FDA clearance",
                        "12 month warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EEG-BISB_AP01_11.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "BISB-AP01-11",
                            description: "Patient Interface Cable (PIC+)",
                            brands: "Covidien",
                            references: "186-0107",
                            package: "1 pcs/bag"
                        }
                    ],
                },
                {
                    title: "BIS Sensors",
                    brands: ["Medtronic/Coviden"],
                    features: [
                        "Compatible with Metronic/Coviden BIS sensors",
                        "High quality performance of signal access",
                        "Big savings"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EEG-BIS电极片.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "",
                            description: "BIS Extend (Extended Use) Sensor",
                            brands: "",
                            references: "186-0160",
                            package: "1 pc/bag"
                        }
                    ],
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>