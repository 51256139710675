<template lang="pug">
#ecg
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 ECG
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_ECG_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Clear signals and secure connections both on the equipment and patient ends are of critical importance for all our replacement ECG products. Our entire range of ECG cables and leadwires are compatible with the following brands: AAMI, Drager/Siemens, GE Marquette, Mindray/Datascope, Nihon Kohden, Philips, Physio Control, Spacelabs, Welch Allyn, Zoll and more.
        .item-wrap(v-for="(sp, i) in ecgData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    components: {
        AboutProductItem,
        MissionPromiseStatement,
        Footer
    },
    data: () => ({
        ecgData: [
        {
            title: "Monitoring Cables",
            brands: ["Philips", "GE Healthcare", "Mindray/Datascope", "Draeger/Siemens", "Nihon Kohden", "Spacelabs"],
            features: [
                "Performance equivalent to OEMs",
                "One-piece Cables, Trunk Cables and Leadwires",
                "Multi-electrodes options: Snap, Grabber and Mini-pinch (for Neonate)",
                "AHA/IEC code available",
                "X-Ray Transparent Leadwires",
                "Variety of adapters to be used with Coviden, Philips, GE compatible Leadwire",
                "CE certificate and FDA 510(K) clearance",
                "6 months warranty"
            ],
            imageUrls: [
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-2-2.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-3-1.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-4.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-5-1.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-6-1.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-7-1.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-8-1.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Cables-and-Leadwires-scaled.jpg"
            ],
            downloadUrls: [
                {
                    url: "https://orantech.com/wp-content/uploads/2021/04/DOC-001-01-Declaration-of-Conformity-ECG-leadwires.pdf",
                    message: "Declaration of Conformity Patient cable and leadwires"
                },
                {
                    url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_ECG_Cables_Leadwires.pdf",
                    message: "ECG Cables and Leadwires User Manual"
                }
            ],
            popularProducts: [
                {
                    number: "ET-5005R-PH-A",
                    description: "Philips ECG 5-lead Trunk Cable,used with IntelliVue lead wires, 2.5m, AHA",
                    brands: "Philips",
                    references: "M1668A",
                    package: "1 pc/bag"
                },
                {
                    number: "ET-5004-MQ-A",
                    description: "Marquette ECG 5-lead wire, grabber,0.9m,AHA",
                    brands: "GE Marquette",
                    references: "412681-001",
                    package: "1 set/bag"
                },
                {
                    number: "EL-MQ5-90G-A",
                    description: "Marquette ECG 5-lead wire, grabber,0.9m,AHA",
                    brands: "GE Marquette",
                    references: "412681-001",
                    package: "1 set/bag"
                },
                {
                    number: "EL-MQV5-90G-A",
                    description: "Marquette ECG 5-lead Chest Leadwire, grabber,0.9m,AHA",
                    brands: "GE Marquette",
                    references: "416467-001",
                    package: "1 set/bag"
                },
                {
                    number: "EL-DG6-90G-I",
                    description: "Drageger/Siemens 6-lead ECG Leadwire, Grabber,0.9m.IEC",
                    brands: "Draeger",
                    references: "MS16157",
                    package: "1 set/bag"
                },
                {
                    number: "ET-6013-NKB-A",
                    description: "Nihon Kohden ECG 6-lead Trunk Cable,2.5m, AHA",
                    brands: "Nihon Kohden",
                    references: "JC-906PA",
                    package: "1 pc/bag"
                },
                {
                    number: "EL-NKB6-90S-A",
                    description: "Nihon Kohden ECG 6-lead wire,Snap,0.9m,AHA",
                    brands: "Nihon Kohden",
                    references: "BR-916PA",
                    package: "1 set/bag"
                },
                {
                    number: "ET-5007R-SL-A",
                    description: "Spacelabs ECG 5-lead Trunk Cable, 2.5m, AHA",
                    brands: "Spacelabs",
                    references: "700-0008-06",
                    package: "1 pc/bag"
                },
                {
                    number: "EL-D5-60S-A",
                    description: "Din Safety 5-lead, Snap, 0.6m, AHA,",
                    brands: "Din Safety",
                    references: "",
                    package: "1 set/bag"
                }
            ],
            },
            {
            title: "Single Use Lead wires",
            brands: ["Coviden/Kendall", "Philips", "GE Healthcare", "Safety Din"],
            features: [
                "Performance equivalent to OEMs",
                "One-piece Cables, Trunk Cables and Lead wires",
                "Electrodes options: Snap and Grabber",
                "AHA/IEC code available",
                "X-Ray Transparent Lead wires",
                "Variety of adapters to switch into the popular lead wires",
                "CE certificate and FDA 510(K) clearance",
            ],
            imageUrls: [
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELD_D5_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELD_DT3_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELD_MQV5_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELD_PH5_90G_I.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELD_TMX5S09AN_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELD_TPH5_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ETD_CV_CV5.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Single-patient-us-lead-wires-compatible-with-Coviden.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Single-patient-us-lead-wires-compatible-with-GE.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Single-patient-us-lead-wires.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Single-patient-use-Coviden-compatible-lead-wires.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Single-patient-use-Coviden-X-ray-transparent-lead-wires.jpg",
            ],
            downloadUrls: [
                {
                    url: "https://orantech.com/wp-content/uploads/2021/04/DOC-001-01-Declaration-of-Conformity-ECG-leadwires.pdf",
                    message: "Declaration of Conformity Patient cable and leadwires"
                },
                {
                    url: "https://orantech.com/wp-content/uploads/2021/02/Disposable_ECG_Leadwires.pdf",
                    message: "Disposable ECG Leadwires Brochure"
                }
            ]
            },
            {
            title: "Telemetry/Holter Cables",
            brands: ["Philips", "GE Healthcare", "Mindray/Datascope", "Fukuda Danshi", "Nihon Kohden", "Draeger/Siemens", "Spacelabs"],
            features: [
                "Equivalent to the major OEMs",
                "Interchangeable with OEM telemetry lead sets",
                "Secured connection with devices",
                "Clear ECG signal access on moving",
                "Reusable and Single-use lead wires",
                "CE certificate and FDA 510(K) clearance",
                "6 months warranty(Reusable only)"
            ],
            imageUrls: [
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Tele-EL_TAA5_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Tele-EL_TAF6_90G_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Tele-EL_TEL5_90S_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Tele-EL_TFD4_90S_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Tele-EL_TPH3_90S_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Telemetry-laed-wires-Philips-MX40-compatible.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Telemetry-lead-wire-Compatible-with-Philips-MX2601B-MX4841A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Telemetry-lead-wires-compatible-with-Philips-M2601A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Telemetry-lead-wires-with-Spo2-Compatible-with-MX40-2.jpg"
            ],
            downloadUrls: [
                {
                    url: "https://orantech.com/wp-content/uploads/2021/02/Telemetry_Cables_and_leadwires.pdf",
                    message: "Telemetry Cables and Leadwires Brochure"
                }
            ],
            popularProducts: [
                {
                    number: "EL-TPH5-90S-A",
                    description: "M2601B/M4841A/5-lead Intellivue with snap and shower shield, 0.9m, AHA",
                    brands: "Philips",
                    references: "989803152071",
                    package: "1 pc/bag"
                },
                {
                    number: "EL-TMX5PH-90S-A",
                    description: "MX40/5-lead IntelliVue wire snap end with Spo2, 0.9m, AHA",
                    brands: "Philips",
                    references: "989803171841",
                    package: "1 pc/bag"
                },
                {
                    number: "EL-TAF6-90S-A",
                    description: "Aprex Pro FH/6-lead with snap end, 0.9m, AHA",
                    brands: "GE Healthcare",
                    references: "394111-008",
                    package: "1 pc/bag"
                },
                {
                    number: "EL-NKB3-90G-I",
                    description: "Nihon Kohden/3-lead with grabber end, 0.9m, IEC",
                    brands: "Nihon Kohden",
                    references: "BR-903P",
                    package: "1 pc/bag"
                },
                {
                    number: "EL-TFD4-90G-I",
                    description: "Fukuda/4-lead with grabber end, 0.9m, IEC",
                    brands: "Fukuda Danshi",
                    references: "",
                    package: "1 pc/bag"
                }
            ],
            },
            {
            title: "Convert Adaptors",
            brands: ["Coviden/Kendall", "Philips", "GE Healthcare", "Mindray", "Nihon Kohden"],
            features: [
                "Switch the existing Yoke or Telemetry to the purposed lead wires",
                "Enable to share the same lead wires with variety of brands equipment",
                "Easy to manage the lead wires inventory",
                "Be used with both reusable or single patient use lead wires",
                "Secured Connection with lead wires",
                "6 months warranty"
            ],
            imageUrls: [
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-Convert-adaptor-ET_5005R25_CV_A-scaled.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELA_TMX5_CV5.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELA_TMX5_MQ5.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELA_TMX5_PH5.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELA_TPH5_CV5.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ET_5004025_CV_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ET_5007R025_CV_I.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ELA_TMX5PH_PH5-scaled.jpg",
            ]
            },
            {
            title: "ECG Supplies",
            brands: ["Universal Purposes"],
            features: [
                "Foam ECG Electrodes",
                "Disposable Non-woven ECG Electrodes",
                "Medical Tape ECG Electrodes",
                "Disposable Micropore ECG Electrodes",
                "Disposable Resting TAB Electrodes",
                "Wet-Liquid Gel ECG Electrodes",
                "Pre-wired Neonate ECG Electrodes",
                "Radiolucent ECG Electrodes",
                "Off-set ECG Electrodes",
                "Cloth Wet/Liquid Gel ECG Electrodes"
            ],
            imageUrls: [
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELDER_D3_60_A.jpg",
                "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ECG-ELDER_D5_90_A.jpg"
            ]
            }
        ]
    })
  }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>