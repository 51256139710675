<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Multi-Parameter
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_Multiparameter_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p We provide replacement trunk cables with parameters of ECG, SpO2 and FiO2 (when present) for the popular brand Drager/Siemens.
        .item-wrap(v-for="(sp, i) in multiParamData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            multiParamData: [
                {
                    title: "Trunk Cables and lead wires",
                    brands: ["Draeger", "Siemens"],
                    features: [
                        "Functionality and performance equivalent to OEMs",
                        "Compatible with OEM's accessories of Spo2, ECG and temp.",
                        "After market replacements saving up to 50%",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Multi-Parameter-Orantech-Drager-compatible-cable-sets.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Multi-Parameter-Orantech-Siemens-compatible-cables.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/multi-EL_SMB3_90G_A.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/multi-SA_028_24.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/multi-TS_DG_AS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Multi-ETM_DG_E6ST.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/multi-EL_DG6_90G_A.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/multi-ETM_SM_E3ST2F.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/multi-ET_3027_D_A.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/02/Multi_Parameter_Trunk_Cable.pdf",
                            message: "Multi Parameter Trunk Cable Brochure"
                        }
                    ],
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>