<template lang="pug">
#nibp
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 NIBP
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_NIBP_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Our entire range of NIBP accessories meet all the performance standards of OEMs and are compatible with popular patient monitoring systems in hospitals around the world including the following brands: Drager/Siemens, Edan, GE, Mindray, Nihon Kohden, Philips, Spacelabs, Welch Allyn and more.
        .item-wrap(v-for="(sp, i) in nibpData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            nibpData: [
            {
                title: "Reusable Cuffs",
                brands: ["Philips", "GE Healthcare", "Mindray/Datascope", "Welch Allyn", "Nihon", "Kohden", "Draeger/Siemens", "Spacelabs"],
                features: [
                    "1-tube and 2-tube Cuffs available",
                    "Patient sizes from Neonate to Large Adult",
                    "Options of Bladderless Cuffs in Orange and Blue inflation bag cuffs",
                    "Pre-applied connectors for the specific brands and models of equipment",
                    "Durable premier TPU materials",
                    "CE certificate and FDA 510(K) clearance",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-2-3.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-3-3.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-4-2.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BP_10BS_W10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BP_50BS_W01M.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Reusable-NIBP-Cuffs.jpg",
                ],
                downloadUrls: [
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_Reusable_NIBP_Cuffs.pdf",
                        message: "Reusable NIBP Cuffs User Manual"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/04/DOC-005-01-Declaration-of-Conformity-NIBP-Cuff.pdf",
                        message: "Declaration of Conformity NIBP Cuff"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/02/NIBP_Reusable_Cuffs.pdf",
                        message: "Reusable NIBP Cuffs Brochure"
                    }
                ]
                },
                {
                title: "Single Use Cuffs",
                brands: ["GE\ Critikon", "Philips", "Welch Allyn", "Draeger/Siemens", "Spacelabs"],
                features: [
                    "1-tube or 2-tube Cuffs available",
                    "Patient sizes from Neonate to Large Adult",
                    "Pre-applied connectors for the specific brands and models of equipment",
                    "Options of Non-woven textile and TPU materials",
                    "CE certificate and FDA 510(K) clearance",
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/2-2-1.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Disposable-NIBP-Cuffs.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPD_12WS_W05P_1.jpg"
                ],
                downloadUrls: [
                    {
                        url: "https://orantech.com/wp-content/uploads/2020/11/Disposable-NIBP-Cuffs.jpg",
                        message: "Declaration of Conformity NIBP Cuff"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/10/2-2-1.jpg",
                        message: "Disposable NIBP Cuffs Brochure"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/11/NIBP-BPD_12WS_W05P_1.jpg",
                        message: "Disposable NIBP Cuffs User Manual"
                    }
                ],
                },
                {
                title: "Sphygmomanometer Accessories",
                features: [
                    "Selection of Replacement parts : Aneroid, Spring tube, Bulb",
                    "Durable and reliable",
                    "Quality guaranteed",
                    "Meet your budget"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-M06_G001.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-M06_T005.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-TNC-NIBP-X_001.jpg",
                ],
                popularProducts: [
                    {
                        number: "TNC-NIBP-X-001",
                        description: "Metal round Pressure Gauge",
                        brands: "General Purpose",
                        references: "NA",
                        package: "1 pc/bag"
                    },
                    {
                        number: "M06-T005",
                        description: "Coiled TPU tube for the 3m of the expanded length,black,φ3.8*7.8mm",
                        brands: "General Purpose",
                        references: "NA",
                        package: "1 pc/bag"
                    },
                    {
                        number: "M06-G001",
                        description: "Pressure pvc bulb with metal stopcock ,black",
                        brands: "General Purpose",
                        references: "NA",
                        package: "1 pc/bag"
                    }
                ],
                },
                {
                title: "Air Hoses",
                brands: ["Philips", "GE Healthcare", "Nihon Kohden", "Mindray", "Draeger", "Fukuda Danshi", "Welch Allyn", "Spacelabs"],
                features: [
                    "Performance equivalent to OEMs",
                    "1-tube or 2-tube air hoses available",
                    "Patient sizes of adult/pediatric and neonate with color coded",
                    "CE certificate and FDA 510(K) clearance",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-6-1.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-Air-Hoses-scaled.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AD12_14A_32.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AD30_13_1011.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AD35_15A_S11.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AS15_07Z_02M.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AS25_42_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AS25_47_02M.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AS30_39_25.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_AS37_18_43.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_N30_07Z_35.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-BPH_ND36_16_17.jpg"
                ],
                downloadUrls: [
                    {
                        url: "https://orantech.com/wp-content/uploads/2022/03/DOC-001-05Declaration-of-Conformity-Patient-Cable-（Air-hose.pdf",
                        message: "Declaration of Conformity Patient Cable （Air hose)"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_NIBP_Air_Hoses.pdf",
                        message: "NIBP Air Hoses User Manual"
                    },
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/04/NIBP_Air_Hoses.pdf",
                        message: "NIBP Air Hoses Brochure"
                    }
                ],
                popularProducts: [
                    {
                        number: "BPH-AS15-07Z-02M",
                        description: "Philips/Adult/Pediatric, Single Tube, 1.5m",
                        brands: "Philips",
                        references: "M1598B",
                        package: "1 pc/bag"
                    },
                    {
                        number: "BPH-NS15-07Z-35",
                        description: "Philips/Neonate, Single Tube, 1.5m",
                        brands: "Philips",
                        references: "M1597C",
                        package: "1 pc/bag"
                    },
                    {
                        number: "BPH-AD36-14A-32",
                        description: "GE/Adult/Pediatric, Double Tube, 3.6m",
                        brands: "GE Healthcare",
                        references: "2058203-002(3.6m), 2058203-003(7.2m)",
                        package: "1 pc/bag"
                    },
                    {
                        number: "BPH-AD35-15A-S11",
                        description: "Nihon Kohden/Adult/Pediatric, Double Tube, 3.5m",
                        brands: "Nihon Kohden",
                        references: "YN-901P",
                        package: "1 pc/bag"
                    }
                ],
                },
                {
                title: "Adaptors and Connectors",
                brands: ["GE Critikon", "Philips", "Welch Allyn", "Draeger/Siemens", "Spacelabs"],
                features: [
                    "The collection of the components enable the hoses or cuffs to be flexible",
                    "Large selection of Connectors meet the needs of specific air Hoses and cuffs",
                    "Short-hose adaptors enable to switch the current 1-tube cuffs to designed 1-tube cuffs",
                    "Y adaptors enable to switch 2-tube to 1-tube cuffs or 1-tube to 2-tube cuffs",
                    "X adaptors for convert the existing double cuffs to purposed double cuffs",
                    "Share the limited SKUs of cuffs to reduce the inventory tremendously",
                    "6 months warranty",
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-2.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-3.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-4.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-5.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-6.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-7.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/NIBP-Connectors.jpg"
                ],
                downloadUrls: [
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/02/NIBP_Connectors.pdf",
                        message: "NIBP Connectors Brochure"
                    }
                ]
                }
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>