<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Fetal
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_Fetal_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p We offer compatible fetal transducers, event mark cable, DECG cable and transducer belts for the after-sales market of fetal monitors of the following primary brands: Analogic, Bionet, Bistos, Edan, GE Corometrics, Huntleigh/Sonicaid, Neoventa, Philips/HP, and Spacelabs.
        .item-wrap(v-for="(sp, i) in fetalData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            fetalData: [
                {
                    title: "Ultrasound/Toco Transducers",
                    brands: ["Philips", "GE Corometrics", "Huntleigh Sonicaid", "Analogic", "Spacelabs/AMS", "Edan", "Bionet", "Bistos", "Neoventa", "Sunny", "Comen", "Jumper"],
                    features: [
                        "Replacements for OEMs, fully compatible",
                        "After market replacements of ultrasound and Toco transducers",
                        "Wide selection of compatible fetal monitoring brands covering most brands in the market",
                        "Significant savings beyond the OEMs",
                        "CE certificate and as contracted manufacturer for 510K clearance transducers",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-Orantech-quality-compatible-transducers.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-Orantech-compatible-transducers-family.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-Orantech-GE-Corometrics-ultrasound-transducer.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FTC_GE01.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-Orantech-Philips-ultrasound-transducer.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-Orantech-Huntleigh-Sonicaid-compatible-Toco-transducer.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FUS_SC04.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-Orantech-Analogic-compatible-ultrasound-transducer.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FUS_AL02_2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FTC_SL02_2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FUS_SL02_2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FTC_SL03_2.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_Fetal_TOCO_Transducers.pdf",
                            message: "Fetal TOCO Transducers User Manual"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-006-02-Declaration-of-Conformity-TOCO-Fetal-transducer.pdf",
                            message: "Declaration of Conformity TOCO Fetal transducer"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "FUS-GE01",
                            description: "GE Corometrics/Ultrasound Transducer",
                            brands: "GE Corometrics",
                            references: "5700LAX",
                            package: "1 set/box"
                        },
                        {
                            number: "FTC-GE01",
                            description: "GE Corometrics/TOCO Transducer",
                            brands: "GE Corometrics",
                            references: "2264LAX",
                            package: "1 set/box"
                        },
                        {
                            number: "FUS-PH01",
                            description: "Philips/Ultrasound Transducer",
                            brands: "Philips",
                            references: "M1356A",
                            package: "1 set/box"
                        },
                        {
                            number: "FTC-PH01",
                            description: "Philips/TOCO Transducer",
                            brands: "Philips",
                            references: "M1355A",
                            package: "1 set/box"
                        }
                    ],
                },
                {
                    title: "Leg plate and Maternal ECG Cables",
                    brands: ["Philips", "GE Corometrics", "Huntleigh Sonicaid"],
                    features: [
                        "Functionality and performance equivalent to OEMs",
                        "Options of fetal spiral electrodes brands: Kendall, Philips, and GE Corometrics",
                        "Price-conscious replacement for aftermarket OEM leg plate/DECG Cables",
                        "Compatible with all major brands of fetal monitors on the market",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Direct-ECG-Cables.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-FDELA_GE01_SGE-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-FDELA_GE02_SGE.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-FDELA_PH01_SGE-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-3-4.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FDELA_PH01_SPE.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FDELA_PH01_SKE_2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FDELA_PH03_SKE.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-5-3.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-6-3.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-EL_MPH2_60S_N.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "FDELA-GE01-SGE",
                            description: "GE-Corometrics Leg Plate adaptor cable",
                            brands: "GE-Corometrics",
                            references: "1591AA0",
                            package: "1 pc/bag"
                        },
                        {
                            number: "FDELA-GE02-SGE",
                            description: "GE-Corometrics Leg Plate adaptor cable",
                            brands: "GE-Corometrics",
                            references: "1590AA0",
                            package: "1 pc/bag"
                        },
                        {
                            number: "FDELA-PH01-SPE",
                            description: "Philips Leg Plate adaptor cable",
                            brands: "Philips",
                            references: "989803137651",
                            package: "1 pc/bag"
                        },
                        {
                            number: "EL-MPH2-60S-N",
                            description: "Philips Maternal ECG lead wire",
                            brands: "Philips",
                            references: "M1363A",
                            package: "1 pc/bag"
                        },
                        {
                            number: "ET-3073-MF",
                            description: "GE-Corometrics ECG Adaptor Y cable",
                            brands: "GE-Corometrics",
                            references: "1442AAO",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "Event Cables",
                    brands: ["Philips/Avalon", "GE Corometrics", "Huntleigh Sonicaid", "Analogic", "Spacelabs/AMS", "Edan", "Bionet", "Bistos", "Neoventa"],
                    features: [
                        "Perform precisely as the OEM",
                        "Price-conscious replacement for aftermarket OEM Event Cables",
                        "Compatible with most models of major Brand fetal monitors",
                        "CE certificate and FDA 510(K) clearance",
                        "6 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/2-1-5.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FM_001.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FM_002.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-FM_003.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-001-02-Declaration-of-Conformity-DECG-Legplate-Adapter-Cables.pdf",
                            message: "Declaration of Conformity DECG Legplate Adapter Cables"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "FM-001",
                            description: "All modelsEvent Mark cable",
                            brands: "Most brands",
                            references: "39198BAO",
                            package: "1 pc/bag"
                        },
                        {
                            number: "FM-002",
                            description: "Philips Event Mark cable",
                            brands: "Philips",
                            references: "989803143411",
                            package: "1 pc/bag"
                        },
                        {
                            number: "FM-003",
                            description: "HuntleighEvent Mark cable",
                            brands: "Huntleigh Sonicaid",
                            references: "7775-6901",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "Fetal supplies",
                    brands: ["Transducer belt", "All brands", "Clips for Philips/Avalon", "Huntleigh/Sonicaid"],
                    features: [
                        "Color coded Transducer belt with button holes",
                        "Quality ultrasound gel",
                        "Record chatter paper"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-M07_B004.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-M07_B001_08.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-M07_B002_08.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-KLF_007_02.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/fetal-KLF_010_06.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "M07-B004",
                            description: "Set of hole belt belts each of Gray and Pink/60mm*1.2mm*130mm",
                            brands: "General Purpose",
                            references: "NA",
                            package: "2 pcs/bag"
                        },
                        {
                            number: "M07-B001-08",
                            description: "Button hole belt/60mm*1.2mm*130cm/Gray, reusable",
                            brands: "General Purpose",
                            references: "NA",
                            package: "1 pc/bag"
                        },
                        {
                            number: "M07-B002-08",
                            description: "Button hole belt /35mm*1.2mm*130cm/Pink, reusable",
                            brands: "General Purpose",
                            references: "NA",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLF-007-02",
                            description: "Philips Avalon Belt Retainer clip",
                            brands: "Philips Avalon",
                            references: "",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLF-010-06",
                            description: "Huntleigh Belt Retainer clip",
                            brands: "Huntleigh Sonicaid",
                            references: "",
                            package: "1 pc/bag"
                        }
                    ],
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>