<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 TEMP &amp; Cardiac Output
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_Temp_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Orantech temperature products cover both long term monitoring as well as quick measurement for patients from neonate to adult. Our compatible products are ideal for after-sales replacements for the following primary brands: Atom, Drager/Siemens, GE, Mindray, Philips, Welch Allyn, YSI and more.
        .item-wrap(v-for="(sp, i) in tempData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            tempData: [
                {
                    title: "Monitoring Probes",
                    brands: ["Philips", "GE Healthcare", "Mindray/Datascope", "Nihon Kohden", "Draeger/Siemens", "Spacelabs", "YSI"],
                    features: [
                        "Compatible with YSI400 and all major brands probes for patient monitors",
                        "Probes type: General (Oral/Rectal) and Skin Surface",
                        "Patient sizes: Adult and Pediatric",
                        "Single or Double channel Adapter Cables for connecting YSI400 Reusable or Single-Use Probes",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty (except Single-Use Probes)"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-compatible-probes-used-with-the-monitors.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-Drager-Siemens-compatible-probes.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-probes-selections.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_BLT_AS30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_DG_AS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_EDA_AS30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_MQ_AS30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_PH_AS30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_Y400_AG30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_Y400_PS30.jpg",
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_Temperature_Adapter_Cables.pdf",
                            message: "Temperature Adapter Cables User Manual"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-003-01-Declaration-of-Conformity-TEMP-Probe.pdf",
                            message: "Declaration of Conformity TEMP Probe"
                        }
                    ]
                },
                {
                    title: "Adaptor Cables",
                    brands: ["Philips", "GE Healthcare", "Mindray/Datascope", "Welch Allyn", "Nihon Kohden", "Draeger/Siemens", "Spacelabs", "YSI"],
                    features: [
                        "Functionally performances as OEM’s",
                        "Compatible with major technologies of 400 and 700 series",
                        "Durable and reliable connection",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-adaptor-single-and-dual-channel.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_PH_AD03.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_MQ_2AD03.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_MRB_AD03.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_MRA_AD03.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_SL_2AD03.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2022/03/DOC-001-06Declaration-of-Conformity-Patient-CableTEMP-Adapter-Cables.pdf",
                            message: "Declaration of Conformity Patient Cable(TEMP Adapter Cables)"
                        }
                    ]
                },
                {
                    title: "Neonate Skin Probes",
                    brands: ["Draeger/Siemens", "Air Shields", "Atom", "Ohmeda", "FANEM", "David"],
                    features: [
                        "Compatible with OEMs",
                        "Specially design for neonate skin temp. measurement",
                        "Precise temp. measurement",
                        "Soft wire and small probe tip",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-neonate-skin-probes.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-neonate-skin-probes-family.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_OH_PS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_OHA_PS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_AS_PS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_DGA_PS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_FA_PS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS-DGB-PS15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TS_DVA_PS15.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-003-01-Declaration-of-Conformity-TEMP-Probe.pdf",
                            message: "Declaration of Conformity TEMP Probe"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/02/Neonate_Skin_Temperature_Probes.pdf",
                            message: "Neonate Skin Temperature Probes Brochure"
                        }
                    ]
                },
                {
                    title: "Single Use Probes and Adaptors",
                    brands: ["GE Healthcare", "Philips", "YSI400"],
                    features: [
                        "Compatible with 400 series temp. technology",
                        "Options of Rectal/Oral and skin probes",
                        "Being used with major brands temp. adaptor cables",
                        "CE certificate and FDA 510(K) clearance"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-Orantech-single-patient-use-probes.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TSD_YSI_AG09.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TSD_YSI_GS09.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA-MQ-2YSI36.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_DG_YSI30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_MRA_YSI30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TSD_PM_AG09.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/TSD_PM_GS09.png",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-TA_PH_PM30.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-003-01-Declaration-of-Conformity-TEMP-Probe.pdf",
                            message: "Declaration of Conformity TEMP Probe"
                        }
                    ],
                },
                {
                    title: "Turbo Probes and Supplies",
                    brands: ["Welch Allyn", "Alaris", "GE Healthcare", "Mindray"],
                    features: [
                        "For quick measurement of temperature",
                        "Probe type: Oral and Rectal",
                        "Predictive time <15 seconds",
                        "Can be used with OEM or compatible disposable probe covers",
                        "Price-conscious replacement for after-sale market",
                        "Compatible with these brands: Welch Allyn, Alaris, GE, and Mindray"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-1-2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Turbo-Temperature-Probes-and-Supplies-scaled.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-3-3.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-5-2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-6-2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-4-2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-7-1.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-003-01-Declaration-of-Conformity-TEMP-Probe.pdf",
                            message: "Declaration of Conformity TEMP Probe"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_Turbo_Temperature_Probes.pdf",
                            message: "Turbo Temperature Probes User Manual"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/02/Turbo_Temperature_probes.pdf",
                            message: "Turbo Temperature Probes Brochure"
                        }
                    ],
                },
                {
                    title: "Cardiac Output Cables",
                    brands: ["Varity", ],
                    features: [
                        "Performance and functionality as same as OEM’s",
                        "Save more than 50% as replacement",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Temp-COCB_DG01_25.jpg"
                    ]
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>